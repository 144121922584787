import moment from "moment";
import 'moment/locale/fr';

import {buildDockConfig, DOCK_TYPE_HARD_ASSIGNED, EXTERNAL_DOCK_TYPES, isAssignable} from "../../utils/dock_n_driver";
import {dockDropOnDefault, dockDropOnTask, dropOnDock} from "../../actions/drag_n_drop";
import {currentToggleValue, DELIVERING_LIST_KEY, DELIVERY_TODO_LIST_KEY} from "../../utils/toggle";
import {SWITCH_TOGGLE} from "../../../../actions/switch_toggle";
import {findByid} from "../../../../utils";
import {extractCenterId} from "../../../../utils/center";
import { isLock as isLocked, filterAndSortDockTasks } from "../../utils/task";
import {
  STATUS_DRAFT,
  STATUS_MOVING,
  STATUS_READY_TO_LOAD,
  STATUS_LOADING,
  STATUS_LOADED,
} from "../../utils/dock_n_driver";
import {
  STATUS_ORDER_PAID,
  STATUS_ORDER_PREP,
} from "../../../../utils/status";
import {
  DISPATCH_MAIN_MODAL_SHOW,
  DISPATCH_MODAL_DRIVERS_SOON,
} from "../../actions/modal";
import { filterOutFrichtiB2cDocks } from "../../utils/dock_n_driver";

moment.locale('fr');

export const dockMovingSort = (a, b) => {
  if (a.tasks.length > 0 && b.tasks.length > 0) {
    return a.earlier_departure_at < b.earlier_departure_at ? -1 : 1;
  }
  return a.id < b.id ? -1 : 1;
};

export const dockLoadingSort = (a, b) => {
  // Les groupements livreurs sont en haut de l'écran
  if (a.dock_type === DOCK_TYPE_HARD_ASSIGNED && b.dock_type !== DOCK_TYPE_HARD_ASSIGNED) {
    return -1;
  }
  if (a.dock_type !== DOCK_TYPE_HARD_ASSIGNED && b.dock_type === DOCK_TYPE_HARD_ASSIGNED) {
    return 1;
  }

  // Les groupements Stuart/Uber doivent être juste après les groupements livreurs
  if (EXTERNAL_DOCK_TYPES.includes(a.dock_type) && EXTERNAL_DOCK_TYPES.includes(b.dock_type)) {
    return -1;
  }
  if (!EXTERNAL_DOCK_TYPES.includes(a.dock_type) && EXTERNAL_DOCK_TYPES.includes(b.dock_type)) {
    return 1;
  }

  // Les groupements livreurs sont triés selon :
  if (a.driver && b.driver && a.dock_type === DOCK_TYPE_HARD_ASSIGNED && b.dock_type === DOCK_TYPE_HARD_ASSIGNED) {
    // - si le groupement est parti en livraison (non nécessaire, car le dock devrait aller dans En livraison)
    if (a.gone_at !== null && b.gone_at === null){ return -1; }
    if (a.gone_at === null && b.gone_at !== null){ return 1; }
    // - si le groupement est en cours de chargement
    if (a.locked_at !== null && a.accepted_by_driver && (b.locked_at === null || !b.accepted_by_driver) ){ return -1; }
    if ( (a.locked_at === null || !a.accepted_by_driver) && b.locked_at !== null && b.accepted_by_driver){ return 1; }
    // - si le groupement est en cours d'acceptation ou presque
    // if (a.locked_at !== null && b.locked_at === null){ return -1; }
    // if (a.locked_at === null && b.locked_at !== null){ return 1; }
    let a_locked_or_with_orders = a.locked_at !== null || a.tasks.length > 0;
    let b_locked_or_with_orders = b.locked_at !== null || b.tasks.length > 0;
    if (a_locked_or_with_orders && !b_locked_or_with_orders){ return -1; }
    if (!a_locked_or_with_orders && b_locked_or_with_orders){ return 1; }
    // Du coup, les groupements livreurs sans tournée assignée sont à la fin.
    // - On trie les égalités jusque ici par le nom du livreur
    return (a.target_name <= b.target_name ? -1 : 1);
  }

  // Les groupements Stuart sont triés selon :
  if (EXTERNAL_DOCK_TYPES.includes(a.dock_type) && EXTERNAL_DOCK_TYPES.includes(b.dock_type)) {
    // - si le groupement a un livreur Stuart/Uber en approche
    if (a.external_job && a.external_job.driver_name !== null && (!b.external_job || !b.external_job.driver_name)){ return -1; }
    if ((!a.external_job || !a.external_job.driver_name) && b.external_job && b.external_job.driver_name !== null){ return 1; }
  }

  // Les groupements sont triés par heure de départ au plus tôt.
  if (a.earlier_departure_at && b.earlier_departure_at) {
    return a.earlier_departure_at < b.earlier_departure_at ? -1 : 1;
  }

  // Si rien ne s'appliquait avant, on trie les docks par id.
  return a.id < b.id ? -1 : 1;
};

const getEarlierQuick = (dock) => {
  let earlier_quick = null;
  if (dock.tasks) {
    dock.tasks.forEach(task => {
      if (task.linked_order && task.linked_order.tags.includes("dqp_express")) {
        if (!earlier_quick || task.linked_order.earlier_departure_at < earlier_quick) {
          earlier_quick = task.linked_order.earlier_departure_at;
        }
      }
    });
  }
  return earlier_quick;
};

const dockTodoSort = (a, b) => {

  // On fait remonter les Quick en premier
  let quickA = getEarlierQuick(a);
  let quickB = getEarlierQuick(b);
  if (quickA && quickB) {
    return quickA < quickB ? -1 : 1;
  } else if (quickA) {
    return -1;
  } else if (quickB) {
    return 1;
  }

  if (a.earlier_departure_at && b.earlier_departure_at) {
    return a.earlier_departure_at < b.earlier_departure_at ? -1 : 1;
  }

  return a.id < b.id ? -1 : 1;
};

export const dockListEventHandler = (dispatch, show_driverqueue_button, showDriversSoonModal) => {
  let handlers = {
    "onDropDefault":        (dockTarget, droppedTask, dockFrom) => dispatch(dockDropOnDefault(dockTarget, droppedTask, dockFrom)),
    "onDropTask":           (targetTask, droppedTask, dockFrom, dockTarget) => dispatch(dockDropOnTask(targetTask, droppedTask, dockFrom, dockTarget)),
    "onDropDock":           (targetDock, droppedDock) => dispatch(dropOnDock(targetDock, droppedDock, !targetDock.tasks.length)),
    "onToggle":             () => dispatch({type: SWITCH_TOGGLE, key: DELIVERY_TODO_LIST_KEY}),
  };
  if (typeof(show_driverqueue_button) !== 'undefined' && show_driverqueue_button === true) {
    handlers.showDriverQueue = (centerId) => {
      window.open(
        `${process.env.REACT_APP_DELEEVADMIN}/livreurs/driver_queue?ref_datetime=now&center_id=${centerId}`,
        "win",
        "height=700,width=850"
      );
    };
  }
  if (typeof(showDriversSoonModal) !== 'undefined' && showDriversSoonModal === true) {
    handlers.showDriversSoonModal = () => dispatch({
        type: DISPATCH_MAIN_MODAL_SHOW,
        modalData: {
          modalType: DISPATCH_MODAL_DRIVERS_SOON,
        }
    });
  }

  return handlers;
};

export const computeDockList = (dock_list, deliveries, docks, drivers, sortingFunc = null) => {
  if (!sortingFunc) {
    sortingFunc = dockTodoSort;
  }
  dock_list = dock_list
    .map(d => Object.assign({}, d , {configChoices : buildDockConfig(d, docks, drivers)}))
    .map(d => ({...d, ...{driver: findByid(drivers, d.target_driver)}}))
    .map(d => ({...d, ...{tasks: filterAndSortDockTasks(deliveries, d.id)}}))
    .map(d => ({...d, assignable: isAssignable(d, docks)}));


  for (let d of dock_list) {
    const isLock = isLocked(deliveries, d);

    // Calcul l'heure de départ au plus tot parmis toutes les tasks
    d.earlier_departure_at = 99999999999999;
    // Vérifie si le dock à une ultraspeed surgelé en cours (packing_on_expe)
    d.has_packing_on_expe = false;
    for (let t of d.tasks) {
      if (t.linked_order.earlier_departure_at < d.earlier_departure_at) {
        d.earlier_departure_at = t.linked_order.earlier_departure_at;
      }

      d.has_packing_on_expe = t.linked_order.pseudo_zones_status.reduce((current, opz) => {
        if ((opz.pseudozone.is_packing_on_expe && opz.is_locked) || current) {
          return true;
        }
        return false;
      }, false);
    }


    if (isLock || !!d.defined_at) {
      d.isLock = isLock;
      for (let t of d.tasks) {
        // si dock defined, on verrouille le dnd de tâche, || t.isLock car verrouillage possible lié à transit area
        // via getDeliveries (le selector)
        t.isLock = isLock || d.locked_at || t.isLock;
        if (t.linked_order.earlier_departure_at < d.earlier_departure_at) {
          d.earlier_departure_at = t.linked_order.earlier_departure_at;
        }
      }
    }
  }

  dock_list =  dock_list.filter(d => filterOutFrichtiB2cDocks(d)).sort(sortingFunc);

  return dock_list;
};

export const dockListProps = (state, dock_list, title) => {
  return {
    title: title ? title : "À livrer",
    "showAll": currentToggleValue(state.toggle, DELIVERY_TODO_LIST_KEY),
    className: 'DockList deliveries-todo',
    show: {
      menu: false,
      displayMode: "one_line",
      driver: false,
      status: false,
      sms: false,
      late: false,
      addressFormatting: "compact",
    },
    docks: dock_list,
    transit_areas: state.dispatch.transit_areas,
    docks_extract: state.dispatch.docks_extract,
    center_id: extractCenterId(state.user),
  };
};

export const computeDockListDeliveries = (dock_list, deliveries) => {
  dock_list = dock_list
    .filter(d => d.tasks.length > 0)
    .sort((a, b ) => {
      return (a.id <= b.id ? -1 : 1);
    });
  for (let d of dock_list) {
    const isLock = isLocked(deliveries, d);
    for (let t of d.tasks) {
      t.isLock = isLock;
    }
  }
  return dock_list;
};

export const dockListDeliveriesProps = (state, dock_list) => {
  return {
    docks: dock_list,
    transit_areas: state.dispatch.transit_areas,
    title: getTitleDockListShipping(state),
    className: 'DockList deliveries-in-progress',
    show: {
      menu: true,
      displayMode: "one_line",
      driver: false,
      status: false,
      sms: false,
      late: false
    },
    "showAll": currentToggleValue(state.toggle, DELIVERING_LIST_KEY),
  };
};

export const dockListDeliveriesHandlers = dispatch => {
  return {
    "onToggle": () => dispatch({type: SWITCH_TOGGLE, key: DELIVERING_LIST_KEY}),
  };
};

export const getTitleDockListDraft = state => {
  let dock_draft = state.dispatch.docks.filter(dock =>
    dock.current_status === STATUS_DRAFT
  );
  return "Tournées en cours de création (" + dock_draft.length + ")";
};

export const getTitleDockListMoving = state => {
  let dock_ids = [];
  let dock_moving = state.dispatch.docks.filter(dock => {
    if (dock.current_status === STATUS_MOVING) {
      dock_ids.push(dock.id);
      return true;
    }
    return false;
  });

  let tasks_not_ready = state.dispatch.deliveries.filter(task => {
    if (
      (task.dock && dock_ids.includes(task.dock.id))
      || (task.dock_target && dock_ids.includes(task.dock_target.id))
    ) {
      return [
        STATUS_ORDER_PAID,
        STATUS_ORDER_PREP,
      ].includes(task.linked_order.status);
    }
    return false;
  });

  let nb_prep_closer = state.dispatch.stats.prep_closer;
  let orders_in_advance = Math.max(tasks_not_ready.length - nb_prep_closer, 0);

  let title = "Tournées en préparation (" + dock_moving.length + ")";
  title += ' ' + tasks_not_ready.length + ' commandes sur';
  title += ' ' + nb_prep_closer + ' préparateurs.';
  title += ' ' + orders_in_advance + " commandes d'avance";

  return title;
};


export function getDriversReadyInLessThan15min(drivers) {
  return drivers.filter(driver => {
    let eta_comparable = driver.eta_comparable;
    try {
      if (eta_comparable !== '') {
        // On récupère l'heure à laquelle le livreur est censé être là
        let parts = eta_comparable.split(":");
        let hours = parts[0];
        let minutes = parts[1];

        // Parfois le eta_comparable n'est pas au bon format ex : 99 h 99
        if (isNaN(hours)) {
          return false;
        }
        let ready_at = moment().hours(hours).minutes(minutes);

        let now = moment();

        // On compare son heure de retour à maintenant,
        // et on retire les trop vieux (ne rentrera pas, n'est pas venu).
        let duration = moment.duration(ready_at.diff(now));
        if (-90 < duration.asMinutes() &&  duration.asMinutes() < 15) {
          return true;
        }
      }
      return false;
    } catch (e) {
      console.log(driver);
      console.error(e);
      return false;
    }
  });
}


export function getDriversReadyInLessThan15minAndAlreadyStarted(
  driversReadyInLessThan15min
) {
  return driversReadyInLessThan15min.filter(d => d.state !== 'plan');
}


export const getTitleDockListReadyToLoad = state => {
  let title = "Tournées Prêtes à livrer";
  let dock_ready_to_go = state.dispatch.docks.filter(dock =>
    [STATUS_READY_TO_LOAD, STATUS_LOADING].includes(dock.current_status)
  );
  let driver_ready_in_less_than_15min = getDriversReadyInLessThan15min(state.dispatch.drivers);

  let ready_in_less_than_15min_and_already_start = getDriversReadyInLessThan15minAndAlreadyStarted(
      driver_ready_in_less_than_15min
  );

  title += ' (' + dock_ready_to_go.length + ')';
  title += ' - environ ' + ready_in_less_than_15min_and_already_start.length  + ' / ' + driver_ready_in_less_than_15min.length + ' livreurs dans les 15 prochaines minutes.';
  if (ready_in_less_than_15min_and_already_start.length > dock_ready_to_go.length) {
    title += ' MANQUE ' + (ready_in_less_than_15min_and_already_start.length - dock_ready_to_go.length);
  }
  return title;
};


export const getTitleDockListShipping = state => {
  let dock_loaded = state.dispatch.docks.filter(dock =>
    dock.current_status === STATUS_LOADED
  );
  return "En livraison (" + dock_loaded.length + ")";
};
