import React from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { api } from "../../../api";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { extractCenterId } from "../../../utils/center";
import { UBER_ORDERS_TO_REGULARIZE_KEY } from "../../../utils/toggle";
import { SWITCH_TOGGLE } from '../../../actions/switch_toggle';
import StoreStatusIndicator from './StoreStatusIndicator';
import UberOrdersTable from './UberOrdersTable';

import '../css/UberEatsOrders.css';

const UberEatsOrders = ({ centerId, displayOrdersToRegularize, toggleOrdersToRegularize }) => {
  const queryClient = useQueryCache();
  const { isLoading, isError, data, error } = useQuery(
    ['uberEatsOrder', centerId],
    (_key, id) => api.listAllUberOrders(id),
    {
      enabled: !!centerId,
      refetchInterval: 10000, // 10secs
    });
  const [changeOrderStatus] = useMutation(({ orderId, status }) => 
    api.changeOrderStatus(orderId, status, 'uber'),
    {
      onSuccess: ({ new_status: newStatus }, { orderId }) => {
        const oldData = queryClient.getQuery(['uberEatsOrder', centerId]);
        const updatedData = oldData.state.data.orders_to_regularize.map(
          o => o.order.id === orderId ? {...o, order: {...o.order, status: newStatus}} : o
        );
        queryClient.setQueryData(
          ['uberEatsOrder', centerId],
          {
            ...oldData.state.data, 
            orders_to_regularize: updatedData
          }
        );
      }
    }
  );

  const defaultTableConfig = {
    withRowColor: true,
    withUberStatusColor: false,
    withOrderStatusActions: false
  }

  return (
    <>
      <StoreStatusIndicator />
      <div className="uber-section-header">
        <h4>Commandes UberDirect à régulariser ({data && data.orders_to_regularize && data.orders_to_regularize.length || 0})</h4>
        <div>
          <button onClick={toggleOrdersToRegularize}>
            {displayOrdersToRegularize ? 'Masquer' : 'Afficher'}
          </button>
        </div>
      </div>
      {data && displayOrdersToRegularize &&
        <UberOrdersTable
          orders={data.orders_to_regularize || []}
          centerId={centerId}
          changeOrderStatus={changeOrderStatus}
          config={{
            ...defaultTableConfig,
            withRowColor: false,
            withUberStatusColor: true,
            withOrderStatusActions: true
          }}
        />
      }
      <div className="uber-section-header">
        <h4>Commandes UberEats & B2C <small>(UberDirect)</small> ({data && data.current_orders && data.current_orders.length || 0})</h4>
      </div>
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {data &&
        <UberOrdersTable
          orders={data.current_orders || []}
          centerId={centerId}
          changeOrderStatus={changeOrderStatus}
          config={defaultTableConfig}
        />
      }
    </>
  );
};

UberEatsOrders.propTypes = {
  current: PropTypes.string,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  location: PropTypes.object,
};

export const ConnectedUberEatsOrders = connect(
  (state) => {
    return {
      centers: (
        state.m.metas.centers
        || state.dispatch.metas.centers
        || []
      ),
      centerId: extractCenterId(state.user),
      displayOrdersToRegularize: state.toggle[UBER_ORDERS_TO_REGULARIZE_KEY],
    };
 },
 (dispatch) => ({
  toggleOrdersToRegularize: () => dispatch({ type: SWITCH_TOGGLE, key: UBER_ORDERS_TO_REGULARIZE_KEY }),
 })
)(UberEatsOrders);

export default ConnectedUberEatsOrders;