import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Badge, Table} from "react-bootstrap";

import {
  showPopinProductCredit,
  showPopinProductRefund,
  showPopinProductChangeStatus,
  showPopinMultipleProductCreditOrRefund,
  SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT,
  SUPPORT_MODAL_SHOW_MULTIPLE_REFUND,
} from "../actions";
import OrderProductOptionBadge from './OrderProductOptionBadge';
import "../css/OrderProducts.css";
import moment from "moment/moment";


const OrderProductPackingStatus = ({status}) => {
  if (status === 'ok' || !status) {
    return null;
  }

  let status_map = {
    'expiration-date': 'DLC dépassée',
    'missing': 'Absent',
    'damaged': 'Abîmé',
    'short-expiration-date': 'DLC courte'
  };

  return <Badge variant="danger">{status in status_map ? status_map[status] : status}</Badge>;
};

const OrderProductVerifStatus = ({order, order_product}) => {
  if (order_product.checking_status === 'done' || !order_product.checking_status ) {
    return null;
  }

  if (!order.intern_packer) {
    return null;
  }

  return <Badge variant="danger">verif-absent</Badge>;
};

const InternalOrderProductChangeStatus = ({order, order_product, meta, showPopinChangeStatus, canShowChangeStatus}) => {
  let old_status = order_product.status;
  return <span>
    {!canShowChangeStatus &&
        meta.order_product_status[old_status]}
    {canShowChangeStatus &&
        <Button size="xs" style={{marginLeft:'3px'}} onClick={() => showPopinChangeStatus(order_product)}><i className="fa-solid fa-pen-to-square"></i> {meta.order_product_status[old_status]}</Button>
    }
  </span>;
};
InternalOrderProductChangeStatus.propTypes = {
  "order": PropTypes.object,
  "meta": PropTypes.object,
  "order_product": PropTypes.object,
  "showPopinChangeStatus": PropTypes.func,
  "canShowChangeStatus": PropTypes.bool
};
const OrderProductChangeStatus = connect(
  (state, ownProp) => {
    return {
      meta: state.support.meta,
      canShowChangeStatus: (
        state.user.is_without_prep_check
        && state.support.meta.order_prepare_status.includes(ownProp.order.status)
      )
    };
  },
  (dispatch) => {
    return {
      "showPopinChangeStatus": (op) => {
        dispatch(showPopinProductChangeStatus(op));
      }
    };
  }
)(InternalOrderProductChangeStatus);


const InternalOrderProducts = (
  {
    order,
    refundDisabled,
    showPopinCredit,
    showPopinRefund,
    showPopinMultipleCredit,
    showPopinMultipleRefund,
    readOnly,
    user,
  }
) => {
  moment.locale('fr');
  let orderProducts = order.products;
  if (order.products.length === 0) {
    return '';
  }
  return <Table size="sm" hover className="OrderProduct">
    <tbody>
      <tr>
        <th></th>
        <th>Produit</th>
        <th></th>
        <th>Statut</th>
        <th>Zone</th>
        <th><Button variant="primary" size="xs" onClick={() => showPopinMultipleCredit(order, user) } disabled={readOnly || order.updating || order.is_uber_eats_order}>Recrédit</Button></th>
        <th><Button disabled={readOnly || refundDisabled} variant="warning" size="xs" onClick={() => showPopinMultipleRefund(order, user) }>Remboursement</Button></th>
      </tr>
      {orderProducts.map(op => {
        let p = op.product;
        if(!p){
          p = {
            id: 0,
            selling_name: "produit inconnu",
            selling_method : "",
            possible_areas: [],
            area: 0,
            shelf: 0,
            tier: 0,
            logistic_title: "",
          };
        }
        let possible_areas = p.possible_areas || [];
        const selling_method = op.selling_method || op.product.selling_method;
        const areas_tooltip = possible_areas.reduce((result, pa, current_index) => {
          if (current_index > 0) {
            result += ',';
          }
          result += ' ' + pa.area_string + ' S:' + pa.real_stock;
          return result;
        }, 'Zonages avec du stock :');

        return <tr key={op.id} className={op.updating ? "updating" : ""}>
          <td><b>{op.quantity}{selling_method === "weight" ? "g" : null}</b></td>
          <td>
            <a href={process.env.REACT_APP_PRODUCTS + `/products/${p.id}`} target="_blank" rel="noopener noreferrer" >
              {p.selling_name}
              {p.logistic_title ? <strong> - {p.logistic_title}</strong> : null}
            </a>&nbsp;

            {op.isReplaced ?
              <Badge variant="default" style={{backgroundColor:"#4f1680"}}>
                remplacé
              </Badge> : null}

            {op.isReplace ?
              <Badge variant="default" style={{backgroundColor:"#7222b8"}}>
                remplacement
              </Badge> : null}

            {op.is_promo ?
              <Badge variant="default" style={{backgroundColor:"#fd7a7a"}}>
                promo
              </Badge> : null}
            {op.quantity_recredit ?
              <Badge variant="default">
                recredit: {op.quantity_recredit}{selling_method === "weight" ? "g" : null}
              </Badge> : null}
            {op.quantity_refund ?
              <Badge variant="default">
                remboursé: {op.quantity_refund}{selling_method === "weight" ? "g" : null}
              </Badge> : null}
            {op.weight && op.weight > 1 ?
              <Badge variant="default" style={{backgroundColor: op.weight > 10 ? "#7D00DC" : "#8989e2"}}>
                {Math.round(op.weight)} k
              </Badge> : null}
            {
              op.options.length > 0 && op.options.map(o =>
                <OrderProductOptionBadge option={o} key={o.id} />
              )
            }
            <OrderProductPackingStatus status={op.packing_status} />
            <OrderProductVerifStatus order={order} order_product={op}/>
          </td>
          <td>
            {op.is_expensive ? <span style={{color:'red'}} title={"Produit cher"}>$$</span>: null}
          </td>
          <td>
            <OrderProductChangeStatus order={order} order_product={op} />
          </td>
          <td title={areas_tooltip}>{p.area}.{p.shelf}.{p.tier}</td>
          <td><Button variant="primary" size="xs" onClick={() => showPopinCredit(op, user) } disabled={readOnly || order.updating || order.is_uber_eats_order}>Recrédit</Button></td>
          <td><Button disabled={readOnly || refundDisabled} variant="warning" size="xs" onClick={() => showPopinRefund(op) }>Remboursement</Button></td>
        </tr>;
      })}
    </tbody>
  </Table>;
};

InternalOrderProducts.propTypes = {
  "order": PropTypes.object,
  "user": PropTypes.object,
  "readOnly": PropTypes.bool,
  "refundDisabled": PropTypes.bool,
  "showPopinCredit": PropTypes.func,
  "showPopinRefund": PropTypes.func,
  showPopinMultipleCredit: PropTypes.func,
  showPopinMultipleRefund: PropTypes.func,
};

export const OrderProducts = connect(
  (state, ownProp) => {
    const replaceOrderProducts = ownProp.order.products.filter(e => e.replacing_data.replace);
    let replaceOrderProductIds = replaceOrderProducts.map(e => e.id);
    let replacedOrderProductIds = replaceOrderProducts.map(e => e.replacing_data.replace);


    for (let op of ownProp.order.products) {
      op.isReplace = replaceOrderProductIds.includes(op.id);
      op.isReplaced = replacedOrderProductIds.includes(op.id);
    }


    return {
      orderProducts : ownProp.order.products.sort(function(a, b) {
        const diffArea = a.product.area - b.product.area;
        const diffShelf = a.product.shelf - b.product.shelf;
        const diffTier = a.product.tier - b.product.tier;
        for (let diff of [diffArea, diffShelf, diffTier]) {
          if (diff !== 0)
            return diff;
        }
        return 0;
      }),
      refundDisabled : (ownProp.order.payment_method !== "stripe"),
    };
  },
  (dispatch) => {
    return {
      "showPopinCredit": (op, user) => {
        dispatch(showPopinProductCredit(op, user));
      },
      "showPopinRefund": (op) => {
        dispatch(showPopinProductRefund(op));
      },
      showPopinMultipleCredit: (order, user) => {
        dispatch(
          showPopinMultipleProductCreditOrRefund(
            order,
            user,
            SUPPORT_MODAL_SHOW_MULTIPLE_CREDIT
          )
        );
      },
      showPopinMultipleRefund: (order, user) => {
        dispatch(
          showPopinMultipleProductCreditOrRefund(
            order,
            user,
            SUPPORT_MODAL_SHOW_MULTIPLE_REFUND
          )
        );
      },
    };
  }
)(InternalOrderProducts);

