import {sortByKey} from "../../../utils";
import { FRICHTICO_SITE_ID } from "../../../utils/site";

export const DOCK_TYPE_HARD_ASSIGNED = 'DRIVER_HARD';
export const DOCK_TYPE_SOFT_ASSIGNED = 'DRIVER_SOFT';
export const DOCK_TYPE_FIRST = 'FIRST';
export const DOCK_TYPE_NOCONFIG = 'NOCONFIG';
export const DOCK_TYPE_STUART = 'STUART';
export const DOCK_TYPE_UBER = 'UBER';

export const EXTERNAL_DOCK_TYPES = [DOCK_TYPE_STUART, DOCK_TYPE_UBER];

export const STATUS_DRAFT = "draft";
export const STATUS_MOVING = "moving";
export const STATUS_READY_TO_LOAD = "ready_to_load";
export const STATUS_LOADING = "loading";
export const STATUS_LOADED = "loaded";
export const STATUS_CLOSED = "closed";

export const VEHICLES_LONG_LABEL = [
  {value:'S50', label: 'Scoot 50'},
  {value:'S125', label:  'Scoot 125'},
  {value:'SELEC', label:  'Scoot ELEC'},
  {value:'CAR', label:  'Voiture'},
  {value:'TRUCK', label:  'Camion'},
  {value:'BIKE', label:  'Vélo'},

];
export const VEHICLES_SHORT_LABEL = {
  'S50': '50',
  'S125': '125',
  'SELEC': 'ELEC',
  'CAR': 'CAR',
  'TRUCK': 'CAM',
  'BIKE': 'VLO',
};


export const isDeletable = function (dock) {

  if (dock.updating) {
    return false;
  }

  if (dock.defined_at) {
    return false;
  }

  if (dock.dock_type !== 'DRIVER_HARD' && !dock.locked_at) {
    return true;
  }

  if (dock.dock_type === 'DRIVER_HARD' && !dock.driver) {
    // Chauffeur non planifié
    return true;
  }

  if (dock.driver && ['plan', 'noplan'].includes(dock.driver.state) && !dock.locked_at) {
    return true;
  }


  return false;
};

export const SORT_BY_TYPE = {
  DOCK_TYPE_HARD_ASSIGNED: 9,
  DOCK_TYPE_SOFT_ASSIGNED: 8,
  DOCK_TYPE_FIRST: 8,
  DOCK_TYPE_NOCONFIG: 8
};

export const isAssignable = function (dock, dockList) {
  // TODO: finir pour les non hard (sans hard present) et implanter
  if (dock.tasks.length === 0) {
    return false;
  }

  if (!dock.driver) {
    return false;
  }

  if (dock.locked_at) {
    return false;
  }

  if (['FIRST', 'NOCONFIG'].includes(dock.dock_type)) {
    return false;
  }

  if (dock.driver.state === "plan") {
    return false;
  }

  let driver_id = dock.target_driver;
  let nbDriverDock = dockList.filter(d => d.target_driver === driver_id).length;

  if (dock.dock_type === 'DRIVER_SOFT' && nbDriverDock > 1 && dock.driver.state !== 'wait') {
    return false;
  }

  return true;
};


export const buildDockConfig = function (dock, dockList, driverList) {
  const existingGoneDriverInDock = dockList
    .filter(d => !!d.target_driver && d.gone_at)
    .map(e => e.target_driver);

  let defaultOptions = [
    {value: "NOCONFIG", label: "Non configuré"},
  ];

  for (let vehicle of VEHICLES_LONG_LABEL) {
    defaultOptions.push({value: "FIRST_" + vehicle.value, label: vehicle.label + ": premier dispo"});
  }
  defaultOptions.push({value: "FIRST_S50125", label: "50/125 : premier dispo"});
  defaultOptions.push({value: "FIRST_SCO", label: "Scoot : premier dispo"});
  defaultOptions.push({value: "FIRST_ALL", label: "Tous : premier dispo"});

  defaultOptions.push({value: "", label: "-----"});

  const driversOption = driverList
    .filter(e => !existingGoneDriverInDock.includes(e.id) || e.id === dock.target_driver)
    .map((e) => { return {value: e.id, label: e.name}; })
    .sort(sortByKey('label'));

  return defaultOptions.concat(driversOption);

};

export const filterOutFrichtiB2cDocks = (dock) => {
  if (dock.dock_type === DOCK_TYPE_UBER) {
    if (dock.tasks && dock.tasks.length === 0) {
      return false
    }

    if (dock.tasks.some(
      t => t.linked_order && t.linked_order.site_id == FRICHTICO_SITE_ID && t.linked_order.canteen_id === null
    )) {
      return false
    }
  }
  return true
}
