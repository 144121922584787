export const getStatusBackgroundColor = (status) => {
  if (status === 'received' || status === 'canceled') {
    return 'done';
  } else if (status === 'prepared' || status === 'shipping') {
    return 'prepared';
  } else if (status === 'prep') {
    return 'preparing';
  }
  return '';
};

export const formatOrderStatus = (status) => {
  if (status === 'paid') {
    return "PAYÉE";
  } else if (status === 'prep') {
    return "EN PRÉP.";
  } else if (status === 'to-check') {
    return "À VÉRIF.";
  } else if (status === 'waiting-products') {
    return "ATTENTE PROD.";
  } else if (status === 'prepared') {
    return "FINALISÉE";
  } else if (status === 'shipping') {
    return "EN LIVRAISON";
  } else if (status === 'received') {
    return "LIVRÉE";
  } else if (status === 'canceled') {
    return "ANNULÉE";
  } else if (status === 'undeliveredclient') {
    return "PAS LÀ";
  } else if (status === 'blocked') {
    return "BLOQUÉE";
  } else if (status === 'stand-by') {
    return "STAND BY";
  }
  return status;
};

export const formatVehicle = (vehicle) => {
  if (vehicle === 'BICYCLE') {
    return 'Vélo';
  } else if (vehicle === 'MOTORBIKE') {
    return 'Moto';
  } else if (vehicle === 'CAR') {
    return 'Voiture';
  } else if (vehicle === 'PEDESTRIAN') {
    return 'À pied';
  }
};

export const formatDeliveryStatus = (status) => {
  if (status === 'SCHEDULED') {
    return 'Planifié';
  } else if (status === 'EN_ROUTE_TO_PICKUP') {
    return 'En chemin pour pickup';
  } else if (status === 'ARRIVED_AT_PICKUP') {
    return 'Arrivé au centre';
  } else if (status === 'EN_ROUTE_TO_DROPOFF') {
    return 'En livraison';
  } else if (status === 'COMPLETED') {
    return 'Livré';
  } else if (status === 'FAILED') {
    return 'Échec';
  }
  return status;
};

export const getOrderWeight = uberOrder => {
  if (uberOrder.order.total_weight) {
    var weight = parseFloat(uberOrder.order.total_weight.replace('Kg', '').trim());
    return Math.round(weight);
  }
  return 0;
};
