import {
  DELIVERY_UPDATE,
  DELIVERY_UPDATE_SUCCEEDED,
  DROP_ON_DEFAULT,
  DROP_ON_DELIVERY_TASK,
  CHANGE_STATUS,
  NUMBER_OF_BAGS,
  DRIVER_UPDATE_SUCCEEDED,
  DOCK_UPDATE,
  DOCK_UPDATED,
  ADD_DOCK,
  CALL_STUART,
  DOCK_CLOSE,
  REFRESH_REQUEST,
  REFRESH_REQUEST_SUCCESS,
  REGULARIZE_STUART,
  CANCEL_STUART,
  CLOSE_ALERT,
  ALERT_CLOSED,
  PACKING_UPDATE_SUCCEEDED,
  DROP_ON_PACKING_DEFAULT,
  DROP_ON_PACKING_TASK,
  LATE_ACTION,
  LATE_WARNED,
  ORDER_UPDATED,
  STOP_DRIVER_PRESENCE,
  CHANGE_DRIVER_VEHICLE,
  START_DRIVER_PRESENCE,
  DOCK_ASSIGN,
  DRIVER_ACTIVITY_START,
  DRIVER_ACTIVITY_END,
  DOCK_UNLOCK,
  FINISH_DOCK,
  DOCK_DROP,
  SHOW_ORDER_INFO,
  HIDE_ORDER_INFO,
  ORDER_PSEUDOZONE_ALTER_STOCK,
  ORDER_PSEUDOZONE_ALTERED_STOCK,
  PSEUDOZONE_UPDATE,
  PSEUDOZONE_UPDATED,
  ORDER_UPDATE,
  ORDER_CALL_STUART,
  REFRESH_REQUEST_OLDIES,
  DOCK_DROP_ON_DELIVERY_TASK,
  DOCK_DROP_ON_DEFAULT,
  GRANT_UPDATED,
  DRIVER_UPDATE,
  DOCK_DEFINE, DOCK_UNDEFINE,
  PLANNINGS_SESSION_UPDATE,
  PLANNINGS_SESSION_UPDATED,
  DRIVER_DEPOSIT_DRIVER_LOAD, DRIVER_DEPOSIT_DRIVER_LOADED,
  DRIVER_DEPOSIT_DRIVERS_LOAD, DRIVER_DEPOSIT_DRIVERS_LOADED,
  DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD, DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED,
  DRIVER_DEPOSIT_MAKE_RETURN, DRIVER_DEPOSIT_CANCEL_RETURNED,
  TRANSIT_AREA_CHANGED, TRANSIT_AREA_LOADED,
  TROLLEY_LOADED, TROLLEY_LOCATION_CHANGED, TROLLEY_LOGS_LOAD, TROLLEY_LOGS_LOADED,
  ORDER_CRATE_ADD,
  ORDER_CRATE_DELETE, REFRESH_REQUEST_ERROR,
  ORDER_STUART_SENDING, DOCK_STUART_SENDING, REFRESH_IGNORE_DISCONNECT,
  ORDER_UBER_SENDING,
  METAS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_EXTRA_DELAY_SAVE,
  METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE,
  METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE,
  METAS_DQP_COURIER_ENABLED_SAVE,
  METAS_RAIN_BONUS_SAVE,
  METAS_SAVED,
  HIDE_ALERT_NOTIFICATIONS,
  LOG_TROLLEY_NOT_FOUND_REVIEWED,
} from "../../actions";
import { ORDER_TO_RESTOCK_SUCCEEDED, VALIDATE_RESTOCK_SUCCEEDED } from "../../actions/canceled_no_shipping";
import {
  ADD_ORDER_TO_XD_DOCK,
  LOADING_MODAL_CRATE_UPDATE,
} from "../../apps/crossdocking/actions";
import { ARRIVALS_CRATE_MODAL_UPDATED } from "../../apps/arrivals/actions";
import refresh from "./refresh";
import order from "./order";
import alert from "./alert";
import driver from "./driver";
import dock from "./dock";
import task from "./task";
import transitAreas from "./transitAreas";
import trolleys from "./trolleys";
import planningsSession from "./planningsSession";
import {filterAndSortDockTasks, isNow} from "../../utils/task";
import {findByid} from "../../utils";
import {TYPE_TROLLEY} from "../../utils/transit_areas";
import {ASSIGN_UPDATE_NEXT, ASSIGN_UPDATED_NEXT, NEXT_TASK_DONE} from "../../actions/nexts";
import {MAIN_MODAL_HIDE, MAIN_MODAL_SHOW} from "../../actions/modal";
import {
  STATUS_ORDER_BLOCKED,
  STATUS_ORDER_XD_TO_CHECK,
  STATUS_ORDER_PREPARED,
  STATUS_ORDER_STAND_BY
} from "../../utils/status";
import { USER_TYPE_REAPPRO_ID } from "../../utils/user";
import {LAUNCHPAD_DEFAULT, LAUNCHPAD_PREPARED_BASKET} from "../../utils/launchpad";
import moment from "moment";
//import sha256 from 'crypto-js/sha256';

/*
function digestMessage(message) {
  // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  // const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  // const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string

  return sha256(message).toString();
}
*/

function initHiddenAlertNotifications(){
  let currentValue = [];
  // Valeur d'origine, lorsque les alertes concernaient uniquement
  // les repréparations de commande
  const legacyValue = localStorage.getItem("hiddenRepreparationAlerts");
  const value = localStorage.getItem("hiddenAlertNotifications");

  if (value) {
    currentValue.push(...JSON.parse(value));
  }
  if (legacyValue) {
    // On met à jour la valeur d'origine dans la nouvelle clé
    // Et on supprime la valeur d'origine
    currentValue.push(...JSON.parse(legacyValue));
    localStorage.setItem("hiddenAlertNotifications", JSON.stringify(currentValue));
    localStorage.removeItem("hiddenRepreparationAlerts");
  }

  return currentValue;
}

const firstInitialState = {
  version: "first_load",
  firstLoad: true,
  versioning: {
    syncFails: 0,
    skipped:0,
    notUpdated:[],
    updated_at: (new Date()).getTime(),
    last_requested_at: (new Date()).getTime(),
  },
  deliveries: [],
  drivers: [],
  docks: [],
  packings: [],
  canceled_deliveries: [],
  alerts: [],
  metas: {},
  modal: {},
  pseudoZones: [],
  nexts: [],
  recurring_incomings:[],
  recurringtask_instances: [],
  incoming_plannings_sessions: [],
  packerstatiph: [],
  packerstatstockpilling: {},
  packerstatsordermoving: {},
  packerstatsbeforepicking: {},
  shipmentstats: {},
  transit_areas: {},
  trolley_logs: [],
  alert_notifications: [],
  hiddenAlertNotifications: initHiddenAlertNotifications(),
  deposit: {
    driver: {
      loading: true,
      datas: {
        count: 0
      },
      products: [],
      debt_datas: {},
    },
    drivers: {
      loading: true,
      showAll: false,
      datas: {
        count: 0
      },
    },
    products: {
      loading: true,
      datas: {
        count: 0
      },
    }
  }
};


function extract_ft(task_list) {
  return task_list.map(e => e.linked_order);
}
function extract_fd(dock_list) {
  return dock_list.reduce((acc, curr) => acc.concat(extract_ft(curr.tasks)), []);
}
function uniq_orders(orders) {
  let map_orders = new Map();
  for (let o of orders) {
    map_orders.set(o.id, o);
  }
  return map_orders;
}


/**
 *  ajoute la version, precalcul les listings, cherche les commandes cachées
 * @param ns
 * @returns {{version: number, hidden_orders: Array, dock_list_todo: {driver: *, tasks: *}[], dock_list_doing: {driver: *, tasks: *}[], deliveries_todo: T[], packing_to_check: T[], packing_waiting: T[], packing_doing: T[], packing_now: T[], packing_later: T[], packing_uber_direct: T[]}}
 */
const preCompute = (ns) => {
  let trolleys_by_id = {};
  if (typeof(ns.metas.trolleys) !== "undefined") {
    ns.metas.trolleys.forEach(trolley => trolleys_by_id[trolley.id] = trolley);
  }

  let transit_areas_by_id = {};
  let transit_areas = ns.transit_areas;
  if (!(ns.transit_areas instanceof Array)) {
    transit_areas = Object.values(ns.transit_areas);
  }
  transit_areas.forEach((transit_area) => {
    if (transit_area.transit_area_type === TYPE_TROLLEY && transit_area.trolley in trolleys_by_id) {
      transit_area.trolley = trolleys_by_id[transit_area.trolley];
    }
    transit_areas_by_id[transit_area.id] = transit_area;
  });

  let pz_by_id = {};
  ns.pseudoZones.forEach(pz => {
    pz_by_id[pz.id] = pz;
  });

  ns.deliveries.forEach(task => {
    task.linked_order.pseudo_zones_status.forEach(opz => {
      opz.pseudozone = pz_by_id[opz.pseudozone_id];
    });
  });
  ns.packings.forEach(task => {
    task.linked_order.pseudo_zones_status.forEach(opz => {
      opz.pseudozone = pz_by_id[opz.pseudozone_id];
    });
  });

  const computed = {
    transit_areas: transit_areas_by_id,
    dock_list_todo: ns.docks
      .filter(d => !d.hidden && !d.gone_at && !d.cross_docking_target_center && !d.name.includes('Transfert Bam'))
      .filter(d => d.launchpad === LAUNCHPAD_DEFAULT)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}})),
    dock_list_basket_todo: ns.docks
      .filter(d => !d.hidden && !d.gone_at)
      .filter(d => d.launchpad === LAUNCHPAD_PREPARED_BASKET)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}})),
    dock_list_doing : ns.docks
      .filter(d => !d.hidden && d.gone_at && !d.finished_at )
      .filter(d => d.launchpad === LAUNCHPAD_DEFAULT)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}}))
      .filter(d => d.tasks.length > 0 && !d.name.includes('Transfert Bam')),
    dock_list_basket_doing: ns.docks
      .filter(d => !d.hidden && d.gone_at && !d.finished_at )
      .filter(d => d.launchpad === LAUNCHPAD_PREPARED_BASKET)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}}))
      .filter(d => d.tasks.length > 0),
    dock_list_cross_docking: ns.docks
      .filter(d => !d.hidden && ((d.cross_docking_target_center && !d.gone_at) || (d.name.includes('Transfert Bam') && !d.gone_at)))
      .filter(d => d.launchpad === LAUNCHPAD_DEFAULT)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}})),
    dock_list_cross_docking_doing : ns.docks
      .filter(d => !d.hidden && d.dock_type === 'DRIVER_HARD' && d.gone_at && !d.finished_at )
      .filter(d => d.launchpad === LAUNCHPAD_DEFAULT)
      .map(d => ({...d, ...{driver: findByid(ns.drivers, d.target_driver)}}))
      .map(d => ({...d, ...{tasks: filterAndSortDockTasks(ns.deliveries, d.id)}}))
      .filter(d => d.name.includes('Transfert Bam')),
    deliveries_todo : ns.deliveries.filter(t =>
      !t.dock &&
      ['todo', 'waiting'].includes(t.status) &&
      t.linked_order.launchpad === LAUNCHPAD_DEFAULT &&
      t.linked_order.status !== STATUS_ORDER_XD_TO_CHECK &&
      !(t.linked_order.status === STATUS_ORDER_PREPARED && t.client.user_type === USER_TYPE_REAPPRO_ID)
    ),
    deliveries_basket_todo : ns.deliveries.filter(t => !t.dock && ['todo'].includes(t.status) && t.linked_order.launchpad === LAUNCHPAD_PREPARED_BASKET),
    tasks_blocked: ns.packings.filter((t) => t.status === STATUS_ORDER_BLOCKED && t.linked_order.status !== STATUS_ORDER_STAND_BY),
    tasks_stand_by : ns.packings.filter((t) => t.linked_order.status === STATUS_ORDER_STAND_BY),
    packing_to_check : ns.packings.filter((t) => t.status === 'to-check'),
    packing_waiting : ns.packings.filter((t) => t.status === 'waiting-products'),
    packing_doing : ns.packings.filter((t) => t.status === 'doing'),
    packing_now : ns.packings.filter((t) => t.status === 'todo' && isNow(t.start_date)),
    packing_later : ns.packings.filter((t) => t.status === 'todo' && !isNow(t.start_date)),
    packing_uber_direct: ns.packings.filter((t) => t.linked_order.site_id === 1015 && t.linked_order.canteen_id === null && !t.linked_order.tags.includes("cp_fallback") && t.status === 'done')
  };

  // extraction des commandes "brut"
  const uniqBrutOrders = uniq_orders(ns.deliveries.map(e => e.linked_order).concat(ns.packings.map(e => e.linked_order)));

  //Warning the mapped orders list may contain no, some, or all late orders.
  //There is no relationship between being a late order and being a hidden order.
  const uniqMappedOrder = uniq_orders(extract_fd(computed.dock_list_todo).concat(
    extract_fd(computed.dock_list_doing),
    extract_ft(computed.deliveries_todo),
    extract_ft(computed.tasks_blocked),
    extract_ft(computed.packing_to_check),
    extract_ft(computed.packing_waiting),
    extract_ft(computed.packing_doing),
    extract_ft(computed.packing_now),
    extract_ft(computed.packing_later),
  ));

  let hiddenOrders = [];
  for (let oid of uniqBrutOrders.keys()) {
    if (!(uniqMappedOrder.has(oid))) {
      hiddenOrders.push(uniqBrutOrders.get(oid));
    }
  }

  hiddenOrders = hiddenOrders.map(
    o => {
      let st = ns.deliveries.find(t => t.linked_order.id === o.id);
      let pt = ns.packings.find(t => t.linked_order.id === o.id);

      o.shipping_address = st ? st.address : pt.address;
      o.client = st ? st.client : pt.client;
      o.dock = st && st.dock && st.dock.id ? st.dock : {};
      o.shipping_time = st ? st.shipping_time : pt.shipping_time;
      o.max_shipping_time = st ? st.max_shipping_time : pt.max_shipping_time;
      o.is_speed = st ? st.is_speed : pt.is_speed;

      const error_no_dock_n_shipping = (!o.dock.id && ['shipping'].includes(o.status) && !o.tags.includes('chronopost'));
      const error_dock_n_end_state_but_unfinished = (!!o.dock && !!o.dock.finished_at && !['canceled', 'received', 'undeliveredclient'].includes(o.status));
      // if (error_no_dock_n_shipping) {
      //   console.warn("commande caché cas erreur 1", o);
      // }
      // if (error_dock_n_end_state_but_unfinished) {
      //   console.warn("commande caché cas erreur 2", o);
      // }
      o.error = error_dock_n_end_state_but_unfinished || error_no_dock_n_shipping;

      return o;
    });

  return {
    ...ns,
    version: 0,
    hidden_orders:hiddenOrders,
    ...computed,
  };
};

const rv = (ns) => {
  ns.version = 0;
  return preCompute(ns);
};


export const mainReducer = (state = firstInitialState, action) => {
  switch (action.type) {
  case DOCK_DROP_ON_DELIVERY_TASK:
    return rv(task.dockDropOnDeliveryTask(action, state));

  case DOCK_DROP_ON_DEFAULT:
    return rv(task.dockDropOnDefault(action, state));

  case DROP_ON_DEFAULT:
    return rv(task.dropOnDefault(action, state));

  case DROP_ON_DELIVERY_TASK:
    return rv(task.dropOnDeliveryTask(action, state));

  case DELIVERY_UPDATE:
    return task.deliveryUpdate(action, state);

  case DELIVERY_UPDATE_SUCCEEDED:
    return rv({ ...state, ...{deliveries: task.updateSucceeded(action, state.deliveries)}});

  case DROP_ON_PACKING_DEFAULT:
    return rv(task.dropOnPackingDefault(action, state));

  case DROP_ON_PACKING_TASK:
    return rv(task.dropOnPackingTask(action, state));

  case PACKING_UPDATE_SUCCEEDED:
    return rv({ ...state, ...{packings: task.updateSucceeded(action, state.packings)}});


  case CHANGE_STATUS:
    return rv(order.changeStatus(action, state));

  case NUMBER_OF_BAGS:
    return rv(order.numberOfBags(action, state));

  case ORDER_TO_RESTOCK_SUCCEEDED:
    return rv({
      ...state,
      canceled_deliveries: state.canceled_deliveries.map(t =>
        t.linked_order.id === action.order.id
          ? {...t, linked_order: action.order, flagged_orders: [action.newFlag]}
          : t
      ),
    });

  case VALIDATE_RESTOCK_SUCCEEDED:
    return rv({
      ...state,
      canceled_deliveries: state.canceled_deliveries.filter(t =>
        t.linked_order.id !== action.order.id
      ),
    });

  case ORDER_UPDATE:
  case ORDER_CRATE_ADD:
  case ORDER_CALL_STUART:
  case ORDER_CRATE_DELETE:
    return rv(order.updateOrder(action, state));
  case ORDER_UPDATED:
    return rv(order.updatedOrder(action, state));

  case ADD_ORDER_TO_XD_DOCK:
    return rv(task.deliveryUpdate(action, state));

  case ORDER_UBER_SENDING:
  case DOCK_STUART_SENDING:
  case ORDER_STUART_SENDING:
    return {...state, modal: {...state.modal, sending: action.sending } };

  case LOADING_MODAL_CRATE_UPDATE:
    if (state.modal.dock) {
      return {
        ...state,
        modal: {
          ...state.modal,
          dock: {
            ...state.modal.dock,
            tasks: state.modal.dock.tasks.map(task =>
              task.linked_order.id === action.order.id
                ? {...task, linked_order: {...task.linked_order, crates: action.order.crates } }
                : task
            )
          }
        }
      };
    } else {
      return {
        ...state,
        modal: {
          ...state.modal,
          task: {
            ...state.modal.task,
            linked_order: {...state.modal.task.linked_order, crates: action.order.crates }
          }
        },
      };
    }

  case ARRIVALS_CRATE_MODAL_UPDATED:
    return {
      ...state,
      modal: {...state.modal, order: action.order }
    };

  case START_DRIVER_PRESENCE:
    return rv(driver.startDriverPresence(action, state));

  case STOP_DRIVER_PRESENCE:
    return rv(driver.stopDriverPresence(action, state));

  case CHANGE_DRIVER_VEHICLE:
    return rv(driver.changeDriverPresence(action, state));

  case DRIVER_UPDATE_SUCCEEDED:
    return rv(driver.driverUpdateSucceeded(action, state));

  case DRIVER_ACTIVITY_START:
    return rv(driver.driverUpdating(action, state));
  case DRIVER_ACTIVITY_END:
    return rv(driver.driverUpdating(action, state));

  case DRIVER_UPDATE:
    return rv(driver.driverUpdating(action, state));

  case DOCK_UPDATE:
    return rv(dock.reduceDockUpdate(action, state));

  case DOCK_UPDATED:
    return rv(dock.reduceDockUpdated(action, state));

  case DOCK_CLOSE:
    return rv(dock.reduceDockClose(action, state));

  case ADD_DOCK:
    return rv(dock.reduceAddDock(action, state));

  case DOCK_ASSIGN:
  case DOCK_UNLOCK:
  case FINISH_DOCK:
  case DOCK_UNDEFINE:
  case DOCK_DEFINE:
  case CANCEL_STUART:
  case REGULARIZE_STUART:
    return rv(dock.reduceDockSimpleAction(action, state));

  case DOCK_DROP:
    return rv(dock.reduceDockDropped(action, state));

  case CALL_STUART:
    return rv(dock.reduceCallStuart(action, state));

  case REFRESH_REQUEST:
  case REFRESH_REQUEST_OLDIES:
    return refresh.refreshRequest(action, state);

  case REFRESH_IGNORE_DISCONNECT:
    return refresh.ignoreDisconnect(state);

  case REFRESH_REQUEST_ERROR:
    return refresh.refreshRequestError(action, state)

  case REFRESH_REQUEST_SUCCESS:
    return preCompute(refresh.refreshRequestSucceded(action, state));

  case LATE_ACTION:
  case LATE_WARNED:
    return rv(task.lateProcessing(action, state));

  case CLOSE_ALERT:
    return rv({...state, ...{alerts: alert.reduceCloseAlert(action, state.alerts)}});

  case ALERT_CLOSED:
    return rv({...state, ...{alerts: alert.reduceAlertClosed(action, state.alerts)}});

  case METAS_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{extra_delay: action.value, updating: true}}}});
  case METAS_EXPRESS_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{express_extra_delay: action.value, updating: true}}}});
  case METAS_EXPRESS_PRIO_EXTRA_DELAY_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{express_prio_extra_delay: action.value, updating: true}}}});
  case METAS_DQP_EXPRESS_PRIO_ENABLED_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{dqp_express_prio_enabled: action.value, updating: true}}}});
  case METAS_DQP_COURIER_ENABLED_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{dqp_courier_enabled: action.value, updating: true}}}});
  case METAS_RAIN_BONUS_SAVE:
    return rv({...state, ...{metas: {...state.metas, ...{rain_bonus_id: action.value, updating: true}}}});
  case METAS_SAVED:
    return rv({...state, ...{metas: {...state.metas, ...{updating: false}}}});

  case SHOW_ORDER_INFO:
    return rv({...state, ...{displayedOrder: action.order}});
  case HIDE_ORDER_INFO:
    return rv({...state, ...{displayedOrder: null}});
  case PSEUDOZONE_UPDATE:
    return rv({
      ...state,
      ...{pseudoZones: state.pseudoZones.map(
        (e) => e.id === action.pseudoZone.id ? {...e, is_active: action.newValue, updating: e.id} : e)
      }
    });
  case PSEUDOZONE_UPDATED:
    return rv({
      ...state,
      ...{pseudoZones: state.pseudoZones.map((e) => e.id === action.pseudoZone.id ? {...action.pseudoZone, updating: false} : e)}
    });
  case ORDER_PSEUDOZONE_ALTER_STOCK:
    return rv({
      ...state,
      ...{packings: state.packings.map((e) => e.id === action.task.id ? {...action.task, updating: true} : e)}
    });
  case ORDER_PSEUDOZONE_ALTERED_STOCK: {
    let logstock_pz = action.task.logstock_pz;
    if (typeof(logstock_pz[action.pseudoZoneId]) === 'undefined') {
      logstock_pz[action.pseudoZoneId] = [];
    }
    logstock_pz[action.pseudoZoneId].push(action.operation);
    return rv({
      ...state,
      ...{packings: state.packings.map((e) => e.id === action.task.id ? {...action.task, updating: false, logstock_pz: logstock_pz} : e)}
    });
  }

  case ASSIGN_UPDATE_NEXT:
    return rv({...state, nexts: state.nexts.map(e => (e.id === action.id ? {...e, updating: true} : e))});

  case NEXT_TASK_DONE: {
    let new_state = {...state, nexts: state.nexts.map(e => (e.id === action.next_id ? {...e, updating: true} : e))};
    return rv(new_state);
  }

  case ASSIGN_UPDATED_NEXT: {
    let new_now = new Date();
    return rv({
      ...state,
      nexts: state.nexts.map(
        e => (e.id === action.response.id ? {
          ...action.response,
          user: {
            ...action.response.user,
            is_working: e.user.is_working,
            has_planning: e.user.has_planning,
          }
        } : e)
      ),
      metas: {...state.metas, now: new_now.toISOString()}
    });
  }

  case MAIN_MODAL_SHOW:
    return rv({...state, modal : action.modalData});
  case MAIN_MODAL_HIDE:
    return rv({...state, modal : {}});

  case DRIVER_DEPOSIT_MAKE_RETURN:
  case DRIVER_DEPOSIT_CANCEL_RETURNED:
  case DRIVER_DEPOSIT_DRIVER_LOAD:
    return rv({...state, deposit: {...state.deposit, driver: {...state.deposit.driver, loading: true}}});

  case DRIVER_DEPOSIT_DRIVER_LOADED:
    return rv({...state, deposit: {...state.deposit, driver: {...action, loading: false}}});

  case DRIVER_DEPOSIT_DRIVERS_LOAD:
    return rv({...state, deposit: {...state.deposit, drivers: {...state.deposit.drivers, loading: true}}});

  case DRIVER_DEPOSIT_DRIVERS_LOADED:
    return rv({...state, deposit: {...state.deposit, drivers: {...action, loading: false}}});

  case DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOAD:
    return rv({...state, deposit: {...state.deposit, products: {...state.deposit.products, loading: true}}});

  case DRIVER_DEPOSIT_PRODUCTS_DETAIL_LOADED:
    return rv({...state, deposit: {...state.deposit, products: {...action, loading: false}}});

  case GRANT_UPDATED: {
    let userNext = state.nexts.find(n => n.user.id === action.user_id);
    userNext = {...userNext, grants:userNext.grants.map(g => (g.identifier === action.res.identifier ? action.res : g))};
    return rv({...state, nexts : state.nexts.map(n => n.id === userNext.id ? userNext : n)});
  }

  case PLANNINGS_SESSION_UPDATE:
    return rv(planningsSession.planningsSessionUpdating(action, state));

  case PLANNINGS_SESSION_UPDATED:
    return rv(planningsSession.planningsSessionUpdated(action, state));

  case TRANSIT_AREA_CHANGED:
    return rv(transitAreas.changed(action, state));

  case TRANSIT_AREA_LOADED:
    return rv(transitAreas.loaded(action, state));

  case TROLLEY_LOCATION_CHANGED:
    return rv(trolleys.changed(action, state));

  case TROLLEY_LOADED:
    return rv(trolleys.loaded(action, state));

  case TROLLEY_LOGS_LOAD:
    return rv(trolleys.logsLoad(action, state));

  case TROLLEY_LOGS_LOADED:
    return rv(trolleys.logsLoaded(action, state));

  case HIDE_ALERT_NOTIFICATIONS:
    return {
      ...state,
      hiddenAlertNotifications: [
        ...state.hiddenAlertNotifications.filter((alert) => {
          // On retire les notifications masquées de plus de 3 heures
          let duration = moment.duration(moment().diff(moment(alert.created_at)));
          return duration.asHours() < 3;
        }),
        // On ajoute les nouvelles notifications à masquer
        ...action.alertNotifications
      ]
    };
  case LOG_TROLLEY_NOT_FOUND_REVIEWED:
    return {
      ...state,
      alert_notifications: state.alert_notifications.filter(
        a => a.type !== "TROLLEY_NOT_FOUND" && a.id !== action.id
      )
    };
  default:
    return state;
  }
};
