import React from "react";

import UberDirectFailureTooltip from "./UberDirectFailureTooltip";
import { formatDeliveryStatus, formatVehicle } from "../utils";

const UberDeliveries = ({ deliveries, withStatusColor }) => {
  const deliveryPartnerNames = [];
  const deliveryPartnerPhones = [];
  const deliveryPartnerVehicles = [];
  const deliveryPartnerStatuses = [];

  deliveries.forEach((delivery, index) => {
    const needDelimiter = deliveries.length > 1 && index % 2 === 0;

    deliveryPartnerNames.push(
      <div key={`${delivery.id}-name`}>
        {delivery.delivery_partner_name || '-'}
        {needDelimiter && <hr/>}
      </div>
    );
    deliveryPartnerPhones.push(
      <div key={`${delivery.id}-phone`}>
        {delivery.delivery_partner_phone_number || '-'} {delivery.delivery_partner_phone_number ? `(${delivery.delivery_partner_pin_code})` : ''}
        {needDelimiter && <hr/>}
      </div>
    );
    deliveryPartnerVehicles.push(
      <div key={`${delivery.id}-vehicle`}>
        {formatVehicle(delivery.delivery_partner_vehicle_type) || '-'}
        {delivery.delivery_partner_vehicle_plate && (
          <span>
            ({delivery.delivery_partner_vehicle_plate})
          </span>
        )}
        {needDelimiter && <hr/>}
      </div>
    );
    deliveryPartnerStatuses.push(
      <div key={`${delivery.id}-status`} className={withStatusColor ? `uber-status-${delivery.status.toLowerCase()}` : ''}>
        {delivery.status === 'FAILED'
          ? <UberDirectFailureTooltip uberDeliveryContext={delivery.uber_direct} />
          : (<>{formatDeliveryStatus(delivery.status)|| '-'}{needDelimiter && <hr/>}</>)
        }
      </div>
    );
  });

  return (
    <>
      <td>
        {deliveryPartnerNames}
      </td>
      <td>
        {deliveryPartnerPhones}
      </td>
      <td>
        {deliveryPartnerVehicles}
      </td>
      <td>
        {deliveryPartnerStatuses}
      </td>
    </>
  );
};

export default UberDeliveries;
