import React from 'react';
import moment from 'moment';
import { Popover, Button, OverlayTrigger } from "react-bootstrap";

const UberDirectFailureTooltip = ({ uberDeliveryContext }) => {



  const popover = (
    <Popover placement="top" title="uber-direct" style={{maxWidth:"500px", border: "1px solid black"}}>
      <ul class="list-unstyled" style={{margin: "0 0.2rem"}}>
        {uberDeliveryContext && uberDeliveryContext.cancel_reason  && <li>Raison de l'annulation: {uberDeliveryContext.cancel_reason}</li>}
        <li>Statut du retour: {uberDeliveryContext ? uberDeliveryContext.return_status : "?"}</li>
        <li>Retour prévu: {uberDeliveryContext ? new Date(uberDeliveryContext.return_dropoff_at).toLocaleString() : "?"}</li>
      </ul>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" overlay={popover} placement='top'>
      <Button variant="danger" size="xs">
        Echec
      </Button>
    </OverlayTrigger>
  );
};

export default UberDirectFailureTooltip;

