import React from "react";
import moment from 'moment';
import { Table, Badge } from "react-bootstrap";
import ExternalShippingJobTooltip from '../../../common-comps/ExternalShippingJobsTooltip';
import BagCountSpan from './BagCountSpan';
import UberDeliveries from "./UberDeliveries";
import {
  getStatusBackgroundColor,
  getOrderWeight,
  formatOrderStatus,
} from "../utils";

const UberOrdersTable = ({ orders, centerId, config, changeOrderStatus }) => {
  const colSpanOrders = config.withOrderStatusActions ? 8 : 7;

  return (
    <Table bordered hover size="sm">
      <thead style={{ textAlign: 'center' }}>
        <tr>
          <th colSpan={colSpanOrders} style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
            Commande
          </th>
          <th colSpan={5} style={{ fontWeight: 'bold', fontSize: '1.7em' }}>
            Uber
          </th>
        </tr>
        <tr>
          <th>Service</th>
          <th>Date de pickup</th>
          <th>ID</th>
          <th>Client</th>
          <th>Statut</th>
          {config.withOrderStatusActions && <th>Actions</th>}
          <th>Emplacement</th>
          <th>Sacs</th>
          <th>Nom de la commande</th>
          <th>Nom du livreur</th>
          <th>Num. du livreur</th>
          <th>Véhicule</th>
          <th>Statut de livraison</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(o => (
          <tr key={`${o.id}:${o.order.id}`} className={config.withRowColor ? `order-row ${getStatusBackgroundColor(o.order.status)}` : ''}>
            <td>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {o.uber_type === 'uber_eats' ? (<Badge style={{ backgroundColor: '#06C167' }}>UberEats</Badge>) : null}
                {o.uber_type === 'uber_direct' ? (<Badge style={{ backgroundColor: 'black' }}>UberDirect</Badge>) : null}
              </div>
            </td>
            <td>
              {o.pickup_time ? moment(o.pickup_time).format('LLL') : '-'}
            </td>
            <td>
              <a
                href={`/support/order-with-center/${o.order.id}/center/${centerId}/`}
                target="_blank"
                rel="noopener noreferrer"
              >{o.order.id} (#{o.order.random_id})</a>
              {
                o.uber_type === 'uber_direct' && getOrderWeight(o) > 33 && (
                  <Badge variant="danger" style={{ marginLeft: '0.5rem' }}>{getOrderWeight(o)}k</Badge>
                )
              }
            </td>
            <td>
              {o.order.fullname} {o.customer_phone_number || '-'} {o.customer_pin_code ? `(${o.customer_pin_code})` : ''}
            </td>
            <td>
              {formatOrderStatus(o.order.status)}
            </td>
            {config.withOrderStatusActions && (
              <td>
                {
                  !['received', 'canceled'].includes(o.order.status) && (
                    <>
                      <button
                        className="btn btn-success btn-xs"
                        style={{ marginRight: '0.3rem' }}
                        title="Livrer la commande"
                        onClick={() => changeOrderStatus({ orderId: o.order.id, status: 'received' })}
                      >Livrer</button>
                      <button
                        className="btn btn-danger btn-xs"
                        title="Annuler la commande"
                        onClick={() => changeOrderStatus({ orderId: o.order.id, status: 'canceled' })}
                      >Annuler</button>
                    </>
                  )
                }
              </td>
            )}
            <td>
              {o.order.transit_area}
            </td>
            <td>
              <BagCountSpan order={o.order} />
            </td>
            <td>
              <span style={{ marginRight: '1rem' }}>{
                o.merchants_link
                  ? (<a target="_blank" rel="noopener noreferrer" href={o.merchants_link}>{o.display_id}</a>)
                  : o.display_id}</span>
              <ExternalShippingJobTooltip externalJobs={o.external_shipping_jobs} />
            </td>
            {o.deliveries && o.deliveries.length ? (
              <UberDeliveries deliveries={o.deliveries} withStatusColor={config.withUberStatusColor} />
            ) : <td colSpan={4} style={{ textAlign: 'center' }}>Commande en retrait</td>}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UberOrdersTable;
