import React from "react";

import { useNumberOfBags } from '../../../hooks/useNumberOfBags';


const BagCountSpan = ({ order }) => {
  const { getFormattedContent } = useNumberOfBags(order);

  return (<span>{getFormattedContent()}</span>);
};

export default BagCountSpan;
