import { createSelector } from 'reselect';
import moment from "moment";

import {STATUS_ORDER_BLOCKED, STATUS_ORDER_CANCELED, STATUS_ORDER_RECEIVED} from "../../../utils/status";
import { XD_FULL_STOCK_ORDER, XD_PARTIAL_ORDER } from '../../../utils/crossDocking';
import {maxShippingSortingFinalized} from "../../../utils/task";
import {findByid} from "../../../utils/index";
import {getDistanceFromLatLonInKm} from "../utils/around";
// import {SUB_DISPATCH_CODE_LIGHT_LOADING_1} from "../sub_dispatchs";

const getTasks = state => state.dispatch.deliveries;
const getFilter = state => state.dispatch.dispatchFilter;
const getCenters = state => state.dispatch.metas.centers;
const getLateOrders = state => state.dispatch.late_orders;
const getPostalCodesFilter = state => state.dispatch.filters.postalCodesArray;
const getNonIDFDepartments = state => state.dispatch.metas.non_idf_departments;
const getSubScreen = state => state.dispatch.subScreen;

export const getDeliveriesToDispatch = createSelector(
  [
    getTasks,
    getFilter,
    getCenters,
    getPostalCodesFilter,
    getNonIDFDepartments,
    getSubScreen,
  ],
  (tasks, filter, centers, postalCodesFilter, nonIDFDepartments, subScreen) => {

    return tasks
      .filter(t => {
        // on filtre les commandes avec dock et transit_area_target_type != start zone
        if (
          (t.dock !== null || t.dock_target !== null)
          && t.is_moving_to_dispatch_list === false
        ) {
          return false;
        }

        // on filtre les commandes staff livrée dans le même centre
        if (t.client.is_staff === true && t.address.latitude && t.address.longitude) {
          let center = findByid(centers, t.linked_order.center_id);
          let distance_km = getDistanceFromLatLonInKm(
            center.address.latitude,
            center.address.longitude,
            t.address.latitude,
            t.address.longitude
          );
          // Si l'adresse est à moins de 100m on considère que c'est l'adresse du centre
          if ((distance_km * 1000) < 100) {
            return false;
          }
        }

        // on filtre sur code postal
        if (filter && filter.type === 'department' && !t.address.postal_code.startsWith(filter.value)) {
          return false;
        }
        // on filtre sur le code postal là encore
        if(filter && filter.type === 'non-idf'){
          let found = false;
          for(let department of nonIDFDepartments){
            if(t.address.postal_code.startsWith(department.code)){
              found = true;
              break;
            }
          }
          if(!found) {
            return false;
          }
        }

        // on filtre sur prep / dispatch prioritaire car peut partir dans la foulé
        if (
          filter && filter.type === 'now'
          && filter.value
          && !(t.linked_order.is_gonable) ) {
          return false;
        }

        // on filtre les cross dock
        if (t.linked_order && t.linked_order.cross_docking !== null) {
          return false;
        }

        // on filtre sur les commandes uber direct
        if (t.linked_order && t.linked_order.site_id === 1015 && t.linked_order.canteen_id === null && !t.linked_order.tags.includes('cp_fallback')) {
          return false;
        }

        // Si nécessaire, on filtre les codes postaux sélectionnés
        // if(subScreen !== SUB_DISPATCH_CODE_LIGHT_LOADING_1) {
        //   postalCodesFilter = []; // filtre désactivé
        // }
        if (postalCodesFilter.length > 0) {
          let hasMatchingPostalCode = false;
          for (let postalCode of postalCodesFilter) {
            if (t.address && t.address.postal_code && t.address.postal_code.startsWith(postalCode)) {
              hasMatchingPostalCode = true;
              break;
            }
          }
          if (!hasMatchingPostalCode) {
            return false;
          }
        }

        return true;
      })
      .sort(maxShippingSortingFinalized)
      .slice(0, 150);
  }
);

export const getDeliveriesLate = createSelector(
  [getTasks, getLateOrders, getPostalCodesFilter],
  (tasks, lateOrders, postalCodesFilter) => {
    const now = moment();
    return tasks.filter((t) => {
      if (!t.linked_order) {
        return false;
      }

      const relatedLateOrder = lateOrders[t.linked_order.id];
      if(relatedLateOrder) {
        return true;
      }

      // Legacy behaviour
      if (t.linked_order.status === STATUS_ORDER_RECEIVED
        || t.linked_order.status === STATUS_ORDER_CANCELED
        || t.linked_order.status === STATUS_ORDER_BLOCKED
        || t.linked_order.cross_docking === XD_FULL_STOCK_ORDER
        || t.linked_order.cross_docking === XD_PARTIAL_ORDER
      ) {
        return false;
      }

      //No Chronopost in late orders
      if(t.linked_order.tags.includes('chronopost')){
        return false;
      }

      // Si nécessaire, on filtre les codes postaux sélectionnés
      if (postalCodesFilter.length > 0) {
        let hasMatchingPostalCode = false;
        for (let postalCode of postalCodesFilter) {
          if (t.address && t.address.postal_code && t.address.postal_code.startsWith(postalCode)) {
            hasMatchingPostalCode = true;
            break;
          }
        }
        if (!hasMatchingPostalCode) {
          return false;
        }
      }

      const st = moment(
        t.linked_order.max_shipping_time ? t.linked_order.max_shipping_time : t.linked_order.shipping_time
      );
      const isCandidate = st.subtract(20, 'minutes').isBefore(now);
      const isAlreadyNotified = (t.linked_order.canceled_mode === 'sms');
      return isCandidate && !isAlreadyNotified;

    }).sort((a, b) => a.shipping_time < b.shipping_time ? -1 : 1);
  }
);
