import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";

import moment from "moment/moment";

import "../css/OrderSummary.css";
import {OrderFraude} from "./OrderFraude";
import {Packer} from "../../../components/Common";

import SupportSelectStatus from "./SupportSelectStatus";
import {TransitAreaDisplay} from "../../../common-comps/TransitAreaDisplay";
import {OrderPrepZoneDisplay} from "./Order/PrepZone/OrderPrepZoneDisplay";
import {showPopinOrderCrates} from "../actions";
import {copyToClipboard} from "../../../utils";
import { showTransitAreaModal } from "../../../actions/modal";
import { NumberOfBags } from "../../../components/NumberOfBags";
import { isSuperAdmin, isSupport, isDockStaff } from '../../../utils/permissions';
import {NumberOfCanteenOrders} from "../../../common-comps/NumberOfCanteenOrders";


const DriverLogHistory = ({order}) => {
  if (!order.driver_logs || order.driver_logs.length === 0) {
    return null;
  }

  let logs_strings = [];
  for (let driver_log of order.driver_logs) {
    logs_strings.push(
      driver_log.driver_name
        + " départ : "
        + (driver_log.started_at === null ? "pas encore" : moment(driver_log.started_at).format("DD/MM/YY à HH:mm"))
        + " arrivée : "
        + (driver_log.ended_at === null ? "pas encore" : moment(driver_log.ended_at).format("DD/MM/YY à HH:mm"))
    );
  }
  const label_history = Array.from(logs_strings).join(', ');

  const tooltip = (
    <Tooltip id={`tooltip-driver-log-order-${order.id}`}>
      {label_history}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={tooltip} transition={false}>
      <span className="transit-area-history float-right"
        onClick={() => {copyToClipboard(label_history);}}
      >
        <i className="fa-solid fa-clock-rotate-left"></i>
      </span>
    </OverlayTrigger>);

};


const InternalOrderSummary = ({
  order,
  pseudoZones,
  transit_areas,
  allCenters,
  userMe,
  customer,
  showTransitAreaModal,
  showModalCrate,
  showExternalJob,
  readOnly,
}) => {
  if (!order) {
    return null;
  }
  moment.locale('fr');

  const deliveredAt = order.shipping_task.date_received ?
    moment(order.shipping_task.date_received).format("H:mm").replace(':', 'h') : 'pas encore livré';
  const shouldBeDeliveredAt = (
    order.shipping_task.date_received
      ? null
      : (order.shipping_task.eta
        ? moment(order.shipping_task.eta).format("H:mm").replace(':', 'h')
        : null
      )
  );

  const prep_zones = order.pseudo_zones_status.filter(opz => !!opz.count_item_match);

  let tags = order.tags.slice(0) || [];
  tags.push(order.api_source);
  if (order.app_version) {
    tags.push(order.app_version);
  }

  let packerMissing = '';
  if (order.missing_task.id) {
    packerMissing = order.missing_task.packer.name;
  }

  let centerName = 'inconnu';
  let center;
  if (order.center > 0) {
    center = allCenters.find(x => x.id === order.center);
    centerName = center ? center.name : "??";
  }
  const isCratesEnabled = center && center.crates_enabled;

  return <Table size="sm" hover className="OrderSummary">
    <tbody>
      <tr>
        <th colSpan={2}>Intervenants et état</th>
      </tr>
      <tr>
        <td>Centre</td>
        <td>{centerName}</td>
      </tr>
      <tr>
        <td>Prep</td>
        <td><Packer task={order.packing_task}/>&nbsp;</td>
      </tr>
      {order.intern_packer ? <tr>
        <td>Vérif</td>
        <td>{order.intern_packer}&nbsp;</td>
      </tr>: null}
      {prep_zones.length > 0
        ? <tr>
          <td>Prep de zone</td>
          <td><OrderPrepZoneDisplay order={order} pseudoZones={pseudoZones} /></td>
        </tr>
        : null}
      {packerMissing
        ? <tr>
          <td>Produit manquants</td>
          <td>{packerMissing}</td>
        </tr>
        : null}
      {order.transit_area || order.log_transit_area.length
        ? <tr>
          <td>Zone avant départ</td>
          <td><TransitAreaDisplay
            order={order}
            transit_areas={transit_areas}
            pseudoZones={pseudoZones}
            readOnly={readOnly && !isDockStaff(userMe)}
            showTransitAreaModal={showTransitAreaModal}/>
          </td>
        </tr>
        : null}
      {isCratesEnabled && order.crates &&
        <tr>
          <td>Cagettes</td>
          <td>
            <Button
              variant="primary"
              size="xs"
              onClick={() => showModalCrate(order)}
            >
              {`${order.crates.length} cagette${order.crates.length > 1 ? 's' : ''}`}
            </Button>
          </td>
        </tr>
      }
      <tr>
        <td>Nombre de sacs</td>
        <td>
          {
            order.canteen_witness
              ? <NumberOfCanteenOrders order={order} />
              : <NumberOfBags order={order} show_full_infos={true} />
          }
        </td>
      </tr>
      <tr>
        <td>Livreur</td>
        <td>
          {order.shipping_task.dock ? order.shipping_task.dock.target_name : " "}
          &nbsp;
          {(isSupport(userMe) || isSuperAdmin(userMe)) &&
            order.shipping_task.dock
            ? order.shipping_task.dock.driver_mobile
            : " "
          }
          <DriverLogHistory order={order} />
        </td>
      </tr>
      {order.last_external_job_id &&
        <tr>
          <td>Dernier job Stuart</td>
          <td>
            <Button
              size="xs"
              onClick={() => showExternalJob(order.last_external_job_id)}
            >
              {order.last_external_job_id}
            </Button>
          </td>
        </tr>
      }
      <tr>
        <td>Mis en livré à</td>
        <td>{deliveredAt}</td>
      </tr>
      <tr>
        <td>Statut</td>
        <td>
          <SupportSelectStatus order={order} customer={customer} />
        </td>
      </tr>
      {shouldBeDeliveredAt
        ? <tr>
          <td>Devrait être livré vers</td>
          <td>
            {shouldBeDeliveredAt}&nbsp;&nbsp;
            {order.shipping_task.dock && order.shipping_task.dock.id
              ? <a href={process.env.REACT_APP_DELEEVADMIN + "/livreurs/logs/group/" + order.shipping_task.dock.id}
                className="link_admin"
                target="_blank" rel="noopener noreferrer"
                title="Contenu de la tournée du chauffeur"
              >
                Tournée
              </a>
              : null
            }
          </td>
        </tr>
        : null
      }
      <tr>
        <td>Fraude</td>
        <td>
          <OrderFraude order={order} />
        </td>
      </tr>
      <tr>
        <td key="a">Autres</td>
        <td key="b">{tags.map((e, i) => <Badge variant="default" key={i}>{e}</Badge>)}</td>
      </tr>
      {order.uber_eats_display_id && (
        <tr>
          <td>Commande UberEats</td>
          <td><a href={order.uber_eats_merchants_link} target="_blank" rel="noopener noreferrer">{order.uber_eats_display_id}</a></td>
        </tr>
      )}
    </tbody>
  </Table>;
};

InternalOrderSummary.propTypes = {
  "order": PropTypes.object,
  "pseudoZones": PropTypes.array,
  "transit_areas": PropTypes.object,
  "allCenters": PropTypes.array,
  userMe: PropTypes.object,
  customer: PropTypes.object,
  "showTransitAreaModal": PropTypes.func,
  showModalCrate: PropTypes.func,
  showExternalJob: PropTypes.func,
  readOnly: PropTypes.bool,
};

export const OrderSummary = connect(
  (state) => ({
    pseudoZones: state.support.meta ? state.support.meta.pseudoZones : [],
    transit_areas: state.support.transit_areas || {},
    allCenters: state.support.meta ? state.support.meta.all_centers : [],
    userMe: state.user,
    customer: state.support.user,
  }),
  (dispatch) => ({
    showTransitAreaModal: (order) => dispatch(showTransitAreaModal(order)),
    showModalCrate: (order) => dispatch(showPopinOrderCrates(order)),
  })
)(InternalOrderSummary);
